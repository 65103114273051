.homeSlider {
    position: relative;
    height: 100vh;
}

.homeSlider .homeList .homeSliderItem {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &::after {
        content: ' ';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    }

    &.active {
        opacity: 1;
        z-index: 10;

        p,
        h2 {
            filter: blur(20px);
            opacity: 0;
            animation: showContent 0.7s 0.3s ease-in-out 1 forwards;
            text-shadow: 1px 0 5px rgba(0, 0, 0, 0.75);
            -webkit-filter: blur(20px);
            -webkit-opacity: 0;
            -webkit-animation: showContent 0.7s 0.3s ease-in-out 1 forwards;
            color:white;
        }

        p {
            animation-delay: 1s;
            -webkit-animation-delay: 1s;
        }
    }

    .sliderItemContent {
        position: absolute;
        left: 3%;
        top: 15%;
        width: 500px;
        max-width: 80%;
        z-index: 1;
        color: #eee;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 10px;
        padding: 0 10px 0 10px;

        h2 {
            width: fit-content;
            font-size: 68px;
            margin: 0;
            white-space: nowrap;
        }

        #prosper{
            font-size: 58px;
        }

        &.rightAligned {
            left: auto;
            right: 3%;
        }
    }
}

.thumbnails {
    position: absolute;
    bottom: -200px;
    z-index: 11;
    display: flex;
    gap: 10px;
    width: 100%;
    height: 250px;
    padding: 0 50px;
    box-sizing: border-box;
    overflow: auto;
    justify-content: center;

    &::-webkit-scrollbar {
        width: 0;
    }

    .thumbnailItem {
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background-color: #ccc;
        transition: 0.7s ease-in-out;
        cursor: pointer;
        flex-shrink: 0;

        &.active {
            background-color: #333;
        }

        &:hover {
            background-color: #666;
        }
    }
}

.aboutUs {
    overflow: hidden;
}

.mountainImg {
    position: fixed;
    opacity: 0.25;
    width: 100%;
    bottom: 0;
    z-index: -10;
    padding-bottom: -10vw;
}

.vertical-line {
    margin-top: 30px;
    width: 0;
    height: 200px;
    border-left: 3px solid;
    border-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)) 1;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translate(-50%, 0);
}

.aboutUsContent {
    position: relative;
    width: 100%;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.35);

    h2 {
        position: relative;
        font-variant: small-caps;
        letter-spacing: 10px;
        text-align: center;
        margin-bottom: 20px;
    }

    p {
        position: relative;
        text-align: center;
        width: 75%;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translate(-50%, 0);
    }

    .membersContainer {
        display: flex;
        justify-content: center;
    
        .team {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            width: 300px;
            transition: all 0.5s ease-in-out;
    
            img {
                border-radius: 50%;
                height: 200px;
                width: 200px;
                margin-top: 20px;
            }
    
            h1 {
                font-size: 20px;
                font-weight: 600;
                margin: 10px 0 50px 0;
                white-space: nowrap;
            }

            &:hover {
                transform: scale(1.1);
            }
        }
    }    
}

@media screen and (max-width: 678px) {

    .homeSlider .homeList .homeSliderItem.prosperImage img {
        object-position: left;
    }

    .homeSlider .homeList .homeSliderItem .sliderItemContent {
        top: 14%;
        left: 6%;

        h2 {
            font-size: 38px;
        }

        #strategic {
            font-size: 48px;
        }

        #diligent {
            font-size: 42px;
        }

        #prosper{
            font-size: 36px;
        }

        &.rightAligned{
            left: 3%;
        }

        p {
            font-size: 15px;
        }
    }

    .membersContainer {
        flex-direction: column;
        align-items: center;
    }

    .aboutParagraph{
        font-size: 12px;
    }

    .vertical-line {
        display: none;
    }
}

@keyframes showContent {
    to {
        transform: translateY(0);
        filter: blur(0);
        opacity: 1;
    }
}

@-webkit-keyframes showContent {
    to {
        -webkit-transform: translateY(0);
        -webkit-filter: blur(0);
        -webkit-opacity: 1;
    }
}