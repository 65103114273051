.about-container {
  position: relative;
  top: 8vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.pageTitle {
  color: #2C3968;
  margin-bottom: 0;
}

.about_italics{
  font-size: 1.2rem ;
}

.team_desc {
  font-size: 1rem;
  color: #666;
  font-variant: small-caps;
  width: 65%;
}

.team-cards {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.team-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 1rem;
  width: 250px;
  text-align: center;
  transition: all 0.5s ease;

  &:hover {
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    cursor: pointer;
    transform: scale(1.05);
  }
}

.team-image {
  border-radius: 50%;
  width: auto;
  height: 200px;
  object-fit: cover;
}

.team-name {
  font-size: 1.5rem;
  margin: 0.5rem 0;
  color: rgb(0, 79, 158);

  &#Emmett {
    font-size: 1.4rem;
  }
}

.team-role {
  font-size: 1rem;
  margin: 0.5rem 0;
  color: #333;
}

.b_social-icons {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 220px;
}

.b_social-icon {
  position: relative;
  height: 2.75vw;
  min-height: 30px;
  transition: all 0.3s ease;

  &:hover {
    scale: (1.2);
  }
}

.about_mountainImg {
  position: fixed;
  opacity: 0.25;
  width: 100%;
  bottom: 0;
  z-index: -10;
  padding-bottom: -10vw;
}

.about-vertical-line {
  border-left: 3px solid;
  border-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)) 1;
  height: 100px;
  margin: 10px 0;
}

.about-title {
  display: none;
}

@media only screen and (max-width: 481px) {
  .about-container {
    text-align: center;
    align-items: center;
    margin-bottom: 12vh;
  }

  .pageTitle{
    white-space: nowrap;
    margin-bottom: 0.5rem;
  }

  .team_desc {
    font-size: 0.65rem;
    width: 325px;
    margin-bottom: 20px;
  }

  .about_italics{
    font-size: 0.85rem ;
  }

  .team-name {
    font-size: 1.1rem;
    &#Emmett {
      font-size: 1.1rem;
    }
  }

  .team-role {
    font-size: 0.75rem;
    margin: 0.5rem 0;
    color: #333;
  }
}