.blog-post {
    position: relative;
    width: 100vw;
    height: fit-content;
    min-height: 100vh;
    top: 8vh;
    padding: 4vh 0 8vh 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow-x: clip;
    display: flex;
    flex-direction: column;
    align-items: center; 

    .cover-image {
        max-width: 90%;
        height: auto;
        border-radius: 10px;
    }

    .title {
        text-align: center;
        font-variant: all-small-caps;
        font-size: clamp(1.125rem, 0.8045rem + 1.3675vw, 1.625rem);
        font-weight: 800;
        margin: 10px 0;
    }

    .blog-content {
        font-family: "Montserrat", sans-serif;
        width: 80%;
        margin: 0 auto;
        text-align: left;
        font-size: clamp(0.875rem, 0.7949rem + 0.3419vw, 1rem);
        font-weight: 500;
        padding: 1rem 2rem;

        background: rgba(255, 255, 255, 0.2);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);

        a {
            color: #2C3968;
            text-decoration: underline;
            transition: color 0.3s;
            white-space: normal;
            word-break: break-word;
            overflow-wrap: break-word;

            &:hover {
                color: #203da7;
                text-decoration: none;
            }
        }

        .date {
            font-variant: all-small-caps;
            font-size: clamp(0.75rem, 1.359rem + 2.735vw, 1.25rem);
            margin: 0 auto;
            font-weight: 600;
            color: #333;
        }
    }

    .author-info {
        width: 85%;
        margin: 0 auto;
        font-family: "Montserrat", sans-serif;

        .author-picture {
            margin-bottom: 12px;
        }

        .author-name {
            font-weight: 700;
            color: #333;
        }
    }

    .mountain-section {
        width: fit-content;

        img {
            position: fixed;
            left: 0;
        }
    }
}