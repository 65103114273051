@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

.services-container{
  position: relative;
  top: 8vh;
  width: 100vw;
  height: fit-content;
  min-height: 100vh;
  margin-bottom: 8vh;
  overflow: hidden;
}

.servicePageTitle{
    position: relative;
    color: #2C3968;
    width: fit-content;
    height: auto;
    left: 50%;
    transform: translateX(-60%);
    font-variant: small-caps;

    span{
      font-size: calc(12px + 0.25vw);
      font-weight: 600;
      font-style: italic;
    }
}

.financialPlanning{
    position: relative;
    left: 2.15%;
    height: 30vw;
    width: 99%;
    text-align: center;
}

.fpInfo{
    position: absolute;
    height: 20vw;
    width: 45vw;
    top: 20%;
    left: 4%;
    align-items: center;
    justify-content: center;
}

.fpImage{
    position: absolute;
    height: 35vw;
    left: 46%;
    top: -2%;
    overflow-x: hidden;
}

.pbPlanning{
    position: relative;
    left: 2.15%;
    height: 30vw;
    width: 97.75%;
    text-align: center;
}

.pbPlanInfo{
    position: absolute;
    height: 20vw;
    width: 38vw;
    top: 20%;
    left: 8%;
    align-items: center;
    justify-content: center;
}

.pbPlanImage{
    position: relative;
    height: 35vw;
    left: 25%;
    top: -10%;
}

.investmentAdv{
    position: relative;
    left: 2.15%;
    height: 30vw;
    width: 99%;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.iaInfo{
    position: absolute;
    height: 20vw;
    width: 40vw;
    top: 20%;
    left: 50%;
    align-items: center;
    justify-content: center;
}

.iaImage{
    position: absolute;
    height: 35vw;
    left: 2%;
    top: -10%;
}

.fpTitle, .iaTitle, .pbPlanTitle{
    font-size: 1.75vw;
    font-weight: 600;
    font-variant: all-small-caps;
    color: #2C3968;
}

.fpDesc, .iaDesc, .pbPlanDesc{
    font-size: 1.25vw;
    font-weight: 400;
    color: #2C3968;
    text-align: center;
}

// Styles for small screen view
.services-container-small {
    position: relative;
    width: 100%;
    margin-top: 70px;
    text-align: center;

    .slick-prev,
    .slick-next {
        font-size: 20px;
        line-height: 1;
        position: absolute;
        top: 35vw;
        display: block;
        width: 2vw;
        height: 2vw;
        padding: 0;
        cursor: pointer;
        color: black; /* Set a contrasting color */
        border: none;
        outline: none;
        background: transparent;
        z-index: 1;
        visibility: hidden;
    }
    
    .slick-prev:before,
    .slick-next:before {
        font-family: 'slick';
        font-size: 4vw;
        line-height: 1;
        opacity: .5;
        color: black; /* Set a contrasting color */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        visibility: visible;
    }

    .slick-prev{
      left: 1.5%;
    }

    .slick-next{
      right: 3.5%;
    }
    
  }
  
  .servicePageTitle-small {
    position: relative;
    top: 10px;
    color: #2C3968;
    font-variant: small-caps;
    font-size: 1.5em;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;

    span{
      font-size: calc(8px + 0.5vw);
      font-weight: 600;
      font-style: italic;
    }
  }
  
  .services-small {
    position: relative;
    height: 100%;
    overflow: hidden;
    top: -5vw;
  }

  .mountainImg-small{
    position: fixed;
    opacity: 0.5;
    width: 150%;
    height: auto;
    bottom: 0;
    left: 0;
    z-index: -10;
    overflow: hidden;
  }
  
  .center-slider-content-small {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }

  .financialPlanning-small,
  .investmentAdv-small,
  .pbPlanning-small {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  
  .fpInfo-small,
  .iaInfo-small,
  .pbPlanInfo-small {
    position: relative;
    left: 20px;
    width: 100%;
    text-align: center; 
    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  
  .fpImage-small,
  .iaImage-small,
  .pbPlanImage-small {
    width: 100%;
    height: auto;
  }

  .fpInfo-small,
  .iaInfo-small,
  .pbPlanInfo-small {
    width: 90%;
    height: auto;
  }
  
  
  .fpTitle-small,
  .iaTitle-small,
  .pbPlanTitle-small {
    font-size: 1.2em;
    font-weight: 600;
    font-variant: all-small-caps;
    color: #2C3968;
  }
  
  .fpDesc-small,
  .iaDesc-small,
  .pbPlanDesc-small {
    font-size: 1em;
    font-weight: 400;
    color: #2C3968;
  }
  