#menu__toggle {
  opacity: 0;
}
#menu__toggle:checked + .small-menu__btn > span {
  transform: rotate(45deg);
}
#menu__toggle:checked + .small-menu__btn > span::before {
  top: 0;
  transform: rotate(0deg);
}
#menu__toggle:checked + .small-menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
}
#menu__toggle:checked ~ .small-menu__box {
  right: 0 !important;
}
.small-menu__btn {
  position: fixed;
  top: 4%;
  right: 4%;
  width: 4vh;
  height: 5vh;
  cursor: pointer;
  z-index: 11;
}
.small-menu__btn > span,
.small-menu__btn > span::before,
.small-menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: white;
  transition-duration: .25s;
}
.small-menu__btn > span::before {
  content: '';
  top: -8px;
}
.small-menu__btn > span::after {
  content: '';
  top: 8px;
}
.small-menu__box {
  display: block;
  position: fixed;
  top: 0;
  right: -100%;
  width: 48vw;
  height: 100vh;
  margin: 0;
  padding: 4vw 0;
  list-style: none;
  text-align: center;
  font-variant: small-caps;
  background-color: #2c396887;
  backdrop-filter: blur(10px);
  transition-duration: .25s;
  z-index: 10;
}

.links{
  position: relative;
  top: 13vw;
}

.small-menu__item {
  display: block;
  padding: 16px 4vh;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: clamp(0.75rem, 0.5969rem + 0.8163vw, 1rem);
  font-weight: 600;
  text-decoration: none;
  transition-duration: .3s;
}
.small-menu__item:hover {
  transform: scale(1.1);
}

.small-schwab_btn{
  height: 8vw;
  width: 8vw;
  border: 2px solid white;
  border-radius: 50%;
}

.small-rightCapital_btn{
  height: 8vw;
  width: 8vw;
  border: 2px solid white;
  border-radius: 50%;
}

.coLogoHam{
  position: relative;
  top: 1.5vw;
  height: 30px;
  width: auto;
}

  .small-logo-container {
    position: absolute;
    top: -0.5vw;
    left: 4vw;
    display: flex;
    align-items: center;
    height: 100%;
  }

  .scheduleBtn_HamMenu{
    position: relative;
    top: 18vw;
    left: 50%;
    transform: translateX(-50%);
  }