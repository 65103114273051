@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&family=Mohave:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

.navbar-container {
  position: fixed;
  display: flex;
  align-items: center;
  font-family: 'Cairo', sans-serif;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
  height: 8vh;
  width: 99vw;
  z-index: 100;
  top: 0;
  padding: 0.25vw 0.5vw;
  background-color: #2C3968;
}


.navbar-left {
  width: 30%;
  display: flex;
  justify-content: flex-start;
}

.navbar-middle {
  width: 30%;
  display: flex;
  justify-content: center;
}

.navbar-right {
  width: 40%;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
}

.coLogo {
  position: relative;
  left: 25%;
  height: auto;
  width: calc(200px + 2vw);
  transition: all 0.5s ease;

  &:hover {
    scale: (0.95);
  }
}

.menu__box {
  position: relative;
  list-style: none;
  display: flex;
  align-items: center;
  margin-right: 1.5vw;
  height: 100%;
}

a {
  text-decoration: none;
}

#dropLink {
  color: white;
  text-decoration: none;

  &:hover {
    color: #2C3968;
  }
}

.dropdown {
  position: relative;

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #2C3968;
    color: white;
    width: 80px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    list-style: none;
    transition: all 0.3s ease;
    left: 50%;
    transform: translateX(-50%);

    li{
      position: relative;
      text-align: center;
      font-size: 14px;
      width: 120px;
      height: fit-content;
      left: -40px;
      top: 3px;
    }

    li:hover{
      background-color: white;
      color: #2C3968;
      cursor: pointer;
    }
  }

  &:hover .dropdown-content {
    display: block;
  }
}

.menu__item {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-decoration: none;
  color: white;
  padding: 10px;
  font-weight: bold;
  font-size: clamp(0.5rem, 0rem + 0.8889vw, 1rem);
  width: fit-content;
  height: 100%;


  &::after {
    content: '';
    position: absolute;
    bottom: 0.1vw;
    left: 10%;
    width: 80%;
    height: .1vw;
    background-color: white;
    transform: scaleX(0);
    transition: transform .3s ease-in-out;
  }

  &:hover::after {
    transform: scaleX(1);
  }
}

#contact_btn{
  margin-right: 1vw;
}

.external_Btn{
  position: relative;
  height: calc(30px + 0.75vw);
  width: calc(30px + 0.75vw);
  border: 2px solid white;
  border-radius: 50%;
  margin-right: 0.25vw;
  margin-left: 10px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover{
    scale: (0.9);
  }
}

.small-navbar-container {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Cairo', sans-serif;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
  height: 8vh;
  width: 100%;
  z-index: 100;
  top: 0;
  padding: 0.25vw 0.5vw;
  background-color: #2C3968;
}