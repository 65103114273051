.jb_about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 800px;
  overflow: hidden;
}

.jb_image-container {
  position: relative;
  display: flex;
  flex: 1;
  width: auto;
  height: 90vh;
  justify-content: center;
  margin-bottom: 20px;
  align-items: center;
  right: 11vw;
}

.jb_about-container {
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0;
  top: 100px;
}

.jb_founderInfo {
  flex: 1;
  align-items: flex-start;
  text-align: left;
}

.jb_social-icons {
  justify-content: flex-start;
}

.jb_founderDescription {
  text-align: left;
  margin: 20px 0;
}

.jb_mobile-foundationImg {
  display: none;
}

.jb_founderImg {
  position: relative;
  display: block;
  width: 40vw;
  height: auto;
  max-width: 450px;
}

.jb_founderInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  position: relative;
  top: 40px;
  right: 12vw;
}

.jb_founderName,
.jb_founderTitle {
  text-align: center;
  font-size: 2em;
  font-weight: 400;
  font-variant: small-caps;
  color: #2C3968;
  position: relative;
}

.jb_founderName:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -0.95vw;
  width: 105%;
  border-bottom: 3px solid #2C3968;
}

.jb_founderTitle {
  font-size: 1.6em;
  font-weight: 800;
  font-variant: all-small-caps;
  text-shadow: 1px 6px 4px rgba(0, 0, 0, 0.15);
}

.jb_social-icons {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.jb_social-icon {
  height: 3vw;
  min-height: 40px;
  transition: all 0.3s ease;

  &:hover {
    scale: (1.2);
  }
}

.jb_founderDescription {
  position: relative;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: calc(12px + 0.25vw);
  color: black;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  padding: 20px;
  width: 90%;
  max-width: 800px;
  margin-bottom: 30px;
}

.jb_indicator-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.jb_indicator {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 5px;
  transition: background-color 0.3s;
}

.jb_indicator.active {
  background-color: #0065C7;
  animation: glow 5s infinite;
}

.jb_mountainImg{
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0.25;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 2px #0065C7;
  }
  25% {
    box-shadow: 0 0 4px #0065C7;
  }
  50% {
    box-shadow: 0 0 5px #0065C7;
  }
  75% {
    box-shadow: 0 0 4px #0065C7;
  }
  100% {
    box-shadow: 0 0 2px #0065C7;
  }
}

@media (max-width: 800px) {
  .jb_about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;
  }

  .jb_image-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 15px;
  }

  .jb_founderInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    top: calc(-1 * (10px + 0.5vw));
    left: 50%;
    transform: translateX(-50%);
  }

  .jb_founderName,
  .jb_founderTitle,
  .jb_social-icons,
  .jb_founderDescription {
    text-align: center;
    width: 100%;
    max-width: 100%;
  }

  .jb_founderName{
    font-size: calc(18px + 2vw);
  }

  .jb_founderName:before {
    bottom: calc(-1 * (10px + 0.5vw));
    width: 80%;
    border-bottom: 3px solid #2C3968;
  }

  .jb_founderTitle{
    font-size: calc(14px + 2vw);
  }

  .jb_mobile-foundationImg {
    position: relative;
    display: block;
    width: 40vw;
    height: auto;
    max-width: 500px;
    min-width: 250px;
    margin: 0 auto;
    left: 12%;
  }

  .jb_founderImg {
    display: none;
  }

  .jb_founderDescription {
    font-size: calc(10px + 0.25vw);
    width: 80%;
    margin-bottom: 100px;
  }

  .jb_mountainImg{
    display: none;
  }
}