.schedule-button-container {
  position: relative;
  display: inline-block;
}

.schedule-button {
  background-color: #ffffff;
  color: #2C3968;
  width: 100%;
  height: 90%;
  padding: 0 1vw;
  border: 3px solid #2C3968;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 800;
  font-variant: all-small-caps;
  transition: background-color 0.35s ease-in-out;
  z-index: 2;
  display: flex;
  align-items: center;

  .separator {
    margin: 0 8px;
  }

  .dropdown-arrow {
    font-size: 12px;
  }

  &:hover {
    color: #ffffff;
    border-color: white;
    background-color: #2C3968;
    font-weight: 1000;
  }

  h1{
    font-size: clamp(0.5rem, 0.2554rem + 1.3043vw, 0.875rem);
  }
}

.dropdown-menu {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translateX(-50%);
  width: 85%;
  background-color: #ffffff;
  border: 1px solid #2C3968;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: -1;

  .dropdown-item {
    padding: 8px 16px;
    cursor: pointer;
    font-size: 12px;
    color: #2C3968;
    text-align: center;

    &:hover {
      background-color: #2C3968;
      color: #ffffff;
    }

  }
}

// Smartphone Adjustments
@media only screen and (max-width: 760px) {
  .schedule-button {
    padding: 0 2vw;
    border: 2px solid #2C3968;
    border-radius: 25px;
    cursor: pointer;
    font-weight: 800;
    font-size: 10px;
    font-variant: all-small-caps;
    transition: background-color 0.35s ease-in-out;
    z-index: 2;

    h1{
      width: 80%;
    }

    .separator {
      margin:  0 4px 0 -6px;
    }

    .dropdown-arrow {
      font-size: 10px;
    }

    &:hover {
      color: #ffffff;
      border-color: white;
      background-color: #2C3968;
      font-weight: 1000;
    }
  }

  .dropdown-menu {
    .dropdown-item {
      padding: 6px 12px;
      font-size: 12px;
    }
  }
}