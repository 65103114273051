@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.disclosures-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    margin-top: 12vh;
    margin-left: -2%;

    .disclosures-header {
        color: #2C3968;
        font-variant: small-caps;
        margin-bottom: 20px;
    }

    .disclosures-list {
        width: 80%;
        max-width: 400px;
        padding: 0;
        list-style: none;

        .disclosures-item {
            height: 40px;
            background-color: #2C3968;
            box-shadow: 5px 5px 5px #cbced1, -5px -5px 5px #ffffff;
            margin-bottom: 20px;
            border: #2C3968 solid 2px;
            border-radius: 10px;
            transition: 0.5s;

            &:hover{
                box-shadow: none;
                background-color: #ffffff;
            }

            &:hover>.disclosures-link{
                color: #2C3968;
            }
        }

        .disclosures-link {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            color: white;
            text-decoration: none;
            font-size: calc(12px + 0.5vw);
            font-family: "Kanit", sans-serif;
            font-weight: 600;
            font-style: normal;
        }
    }
}

.MountainFooter{
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 90%;
    z-index: -1;
    opacity: 0.5;
}
