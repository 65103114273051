@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');

.circularButton {
  position: fixed;
  border-radius: 50%;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  bottom: 15px;
  right: 14px;
  z-index: 99;
  transition: opacity 1s ease;

  .tooltip {
    position: absolute;
    bottom : 25%;
    right: 75%;
    width: 110px;
    height: 30px;
    background-color: #1C548C;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    font-variant: small-caps;
    color: #ffffff;
    display : none;
    z-index: 99;
    padding: 0;

    .tooltipText{
      position: relative;
      top: 15%;
    }
  }

  &:hover > .tooltip {
    display: block;
  }
}

.contactImg {
  height: 50px;
  width: 50px;
  filter: drop-shadow(0px 4px 8px rgb(0, 0, 0, 0.5));
  transition: all 1s ease;

  &:hover {
    scale: 0.95;
    filter: none;
  }
}

.box {
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 650px;
  color: #555;
  background-color: #ecf0f3;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  z-index: 101;

  .contactModalForm{
    width: 500px;
    height: 600px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    input,
    select,
    textarea{
      position: relative;
      top: 70px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      width: 90%;
      margin-bottom: 10px;
      border: none;
      outline: none;
      background: none;
      color: #555;
      padding: 15px;
      border-radius: 10px;
      box-shadow: inset 5px 5px 5px #cbced1,
          inset -5px -5px 5px #ffffff;
      transition: 0.5s;

      &::placeholder{
        text-align: center;
      }

      &:hover{
        animation: pulse 3s linear infinite;
      }

      &:focus{
        &::placeholder{
          color: transparent;
        }
        animation: none;
        text-align: left;
      }
    }

    button{
      position: relative;
      top: 70px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
    }

    #formEmail, #formPhone{
      width: 41%;
      left: 128px;
    }

    #formEmail{
      margin-right: 8px;
    }

    #formSelect{
      appearance: none;
      width: 96%;
      text-align: center;
      background: url("../../images/select_arrow.png") no-repeat 96% #ecf0f3;

      &:hover{
        animation: pulse 3s linear infinite;
      }
    }

    #formMessage{
      height: 120px;
      resize: none;
      overflow: scroll;

      &::placeholder{
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    #submitBtn{
      position: relative;
      width: 25%;
      height: 40px;
      border-radius: 10px ;
      border: none;
      box-shadow: 5px 5px 5px #cbced1,
       -5px -5px 5px #ffffff;
      transition: 0.3s;
      font-size: 18px;
      font-variant: small-caps;
      font-weight: 800;
      background-color: #2C3968;
      color: white;
      z-index: 100;

      &:hover{
        background-color: #00A36C;
        box-shadow: inset 5px 5px 5px #00764f,
        inset -5px -5px 5px #00c382;
      }
    }


  }
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  font-family: "Mukta", sans-serif;
  font-weight: 800;
  font-style: normal;
  transition: 0.3s;
  color: #2C3968;

  &:hover{
    scale: (0.8);    
  }
}

.formImg{
  position: fixed;
  bottom: 0;
  border-radius: 16px;
  z-index: 99;
  width: 100%;
  filter: drop-shadow(0px -3px 6px rgba(0, 0, 0, 0.5));
}

.boxTitle{
  position: fixed;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  font-variant: small-caps;
  font-size: 24px;
  color: #2C3968;
}

@media only screen and (max-width: 539px) {
  .circularButton {
    right: 25px;

    .tooltip {
      bottom : 30%;
      width: 100px;
      height: 20px;
      font-size: 14px;
    }
  }

  .contactImg {
    height: 40px;
    width: 40px;
  }

  .box {
    top: 50%;
    height: 100%;
    width: 100%;
    border-radius: 0;
    .contactModalForm{
      width: 100%;
      height: 90%;
      border-radius: 0;

      #formEmail, #formPhone{
        width: 90%;
        left: 50%;
        margin-right: 0;
      }

      #formMessage{
        height: 150px;
      }

      #formSelect{
        width: 97%;
      }

      #submitBtn{
        font-size: 14px;
      }

    }
  }

  .formImg{
    border-radius: 0;
  }
}

@keyframes pulse {
  0% {
    box-shadow: inset 0 0 5px rgba(44,57,104, 0.2); /* Initial state with no shadow */
  }
  50% {
    box-shadow: inset 0 0 10px rgba(44,57,104, 1); /* Shadow pulses in */
  }
  100% {
    box-shadow: inset 0 0 5px rgba(44,57,104, 0.2); /* Shadow disappears */
  }
}
