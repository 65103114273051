.PageNotFoundBlock{
    width: 100%;
    height: 100vh;
    
    div {
        display: block;
    }

    h1 {
        display: block;
        font-size: 2em;
        margin-block-start: 0.67em;
        margin-block-end: 0.67em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
    }

    h2 {
        display: block;
        font-size: 1.5em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
    }
}

#notFound{
    position: relative;
    height: 100vh;

    .notFound{
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}

.notFound {
    max-width: 520px;
    width: 100%;
    line-height: 1.4;
    text-align: center;

    .notfound-404{
        position: relative;
        height: 200px;
        margin: 0 auto 20px;
        z-index: -1;
    }

    a{
        font-family: montserrat, sans-serif;
        display: inline-block;
        font-weight: 700;
        text-decoration: none;
        color: #fff;
        text-transform: uppercase;
        padding: 13px 23px;
        background: #2C3968;
        font-size: 18px;
        -webkit-transition: .2s all;
        transition: .2s all;
        border: 2px solid white;

        &:hover{
            color: #2C3968;
            background: #fff;
            border-color:#2C3968;
        }
    }
}

.notfound-404{
    position: relative;

    h1{
        font-family: montserrat, sans-serif;
        font-size: 236px;
        font-weight: 200;
        margin: 0;
        color: #211b19;
        text-transform: uppercase;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    h2{
        font-family: montserrat, sans-serif;
        font-size: 28px;
        font-weight: 400;
        text-transform: uppercase;
        color: #211b19;
        background: #fff;
        padding: 10px 5px;
        margin: auto;
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

@media only screen and (max-width: 767px)
{
    .notFound{
        .notfound-404{
            h1{
                font-size: 148px;
            }

            h2{
                font-size: 20px;
                bottom: -1vw;
            }
        }

        a{
            padding: 7px 15px;
            font-size: 14px;
        }
    }
}

@media only screen and (max-width: 480px)
{
    .notFound{
        .notfound-404{
            height: 148px;
            margin: 0 auto 10px;

            h1{
                font-size: 86px;
            }

            h2{
                font-size: 16px;
            }
        }

        a{
            padding: 7px 15px;
            font-size: 14px;
        }
    }
}