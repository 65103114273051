.texture-background {
    position: relative;
    padding: 2rem;
    height: fit-content;
    min-height: 100vh;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-image: url('../../images/insight-texture-bg.jpg');
        background-size: cover;
        background-position: center;
        opacity: 0.5;
        z-index: 0;
        background-attachment: fixed;
    }

    .insight-container {
        position: relative;
        top: 14vh;
        display: grid;
        grid-template-columns: repeat(3, 30%);
        gap: 2rem;
        padding: 1rem;
        width: calc(100vw - 5.9rem);
        height: fit-content;
        margin-bottom: 12vh;
        z-index: 1;
        justify-content: center;

        .blog-card {
            margin: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 12px;
            padding: 1.5rem;
            height: fit-content;
            text-align: left;
            background: rgba(255, 255, 255, 0.18);
            border-radius: 16px;
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            border: 1px solid rgba(255, 255, 255, 0.5);


            .cover-image {
                width: 100%;
                height: 300px;
                object-fit: cover;
                background-position: center;
                border-radius: 12px;
                margin-bottom: 1rem;
            }

            .blog-title, .summary{
                margin: 5px;
            }

            .blog-title {
                width: 100%;
                height: calc(1.5em * 2);
                font-size: clamp(0.875rem, 0.6346rem + 1.0256vw, 1.25rem);
                font-weight: bold;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
                line-height: 1.5;
            }

            .summary {
                width: 100%;
                height: calc(1.5em * 4);
                font-size: clamp(0.75rem, 0.5897rem + 0.6838vw, 1rem);
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 4;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
                line-height: 1.5;
            }

            .blog-redirect-btn {
                background-color: #2C3968;
                color: white;
                padding: 0.75rem 1rem;
                border-radius: 5px;
                border: none;
                cursor: pointer;
                margin: 1rem 0;
                align-self: flex-start;

                &:hover {
                    background-color: #203da7;
                }
            }

            .author-info {
                display: flex;
                align-items: center;
                width: 100%;
                margin-top: 1rem;

                .publish-date {
                    font-size: clamp(0.5rem, 0.3912rem + 0.7059vw, 0.6rem);
                    font-variant: all-small-caps;
                    font-weight: bold;
                }

                .author-picture {
                    position: relative;
                    top: 12px;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    margin-left: 0.75rem;
                    border: 2px solid black;
                }
            }
        }
    }

    .search {
        position: absolute;
        top: 12vh;
        right: 9%;
        width: 200px;
        height: 40px;
        z-index: 2;
    }
    
    .search__input {
        width: 100%;
        height: 100%;
        padding: 5px 15px;
        font-size: 14px;
        border: none;
        outline: none;
        border-radius: 20px;
        transition: all 0.3s ease;
        padding-right: 35px;
        background: rgba(255, 255, 255, 0.18);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid #2C3968;
    }
    
    .search__input:focus {
        background: #ffffff;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }
    
    .search__submit {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        font-size: 16px; /* Slightly smaller icon size */
        color: #666;
        transition: color 0.3s ease;
    }
    
    .search__input::placeholder {
        color: #999;
        font-variant: all-small-caps;
    }
}

.no-results {
    position: absolute;
    top: 30vh;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.5rem;
    font-weight: 700;
    color: white;
    background-color: #2c396898;
    backdrop-filter: blur(10px);
    border-radius: 10px;
    padding: 0 15px;
    text-align: center;
    -webkit-box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.5);
    box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.5);
}

@media (min-width:751px) and (max-width: 1200px) {
    .insight-container {
        grid-template-columns: repeat(2, 45%) !important;
        display: flex !important;
        
    }

    .blog-card {
        width: 100% !important;
        max-width: none !important;
        margin-bottom: 1.5rem !important;
    }

    .search {
        width: 150px !important;
        right: 70px !important;
        margin-bottom: 20px !important;
    }
}

@media (max-width: 750px) {
    .insight-container {
        grid-template-columns: 1fr !important;
        padding: 1rem !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important; 
    }

    .blog-card {
        width: 95% !important;
        max-width: none !important;
        margin-bottom: 1.5rem !important;
    }

    .search {
        width: 150px !important;
        right: 70px !important;
        margin-bottom: 20px !important;
    }
}